import React, { useState } from 'react';
import './home.css';
import alfalaval from '../../assets/images/logo-alfalaval.svg'
import iosImg from '../../assets/images/ion.jpeg'
import sign from '../../assets/images/sign.png';
import Header from '../header/header';
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";

import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
const AutoplaySlider = withAutoplay(AwesomeSlider);

const logo = ['https://www.astechmarketing.com/images/acc.png', 'https://www.astechmarketing.com/images/Adani.png', 'https://www.astechmarketing.com/images/aditya birla.jpg', 'https://www.astechmarketing.com/images/APCPL.jpeg', 'https://www.astechmarketing.com/images/Bhel.png', 'https://www.astechmarketing.com/images/birlacorp.png', 'https://www.astechmarketing.com/images/CLP.png', 'https://www.astechmarketing.com/images/GVK.png', 'https://www.astechmarketing.com/images/hmel.jpeg', 'https://www.astechmarketing.com/images/HPGCL.png', 'https://www.astechmarketing.com/images/Indian-oil.png', 'https://www.astechmarketing.com/images/IPGCL.png', 'https://www.astechmarketing.com/images/jaypee.png', 'https://www.astechmarketing.com/images/jubliant.png', 'https://www.astechmarketing.com/images/khyber-logo.png', 'https://www.astechmarketing.com/images/Lanco.png', 'https://www.astechmarketing.com/images/LT.png', 'https://www.astechmarketing.com/images/Maruti.png', 'https://www.astechmarketing.com/images/Meja.png', 'https://www.astechmarketing.com/images/National_Fertilizers_Logo.svg.png', 'https://www.astechmarketing.com/images/NHPC.jpeg', 'https://www.astechmarketing.com/images/NTPC.png', 'https://www.astechmarketing.com/images/PPCL.png', 'https://www.astechmarketing.com/images/PSPCL.png', 'https://www.astechmarketing.com/images/Reliance-Energy.png', 'https://www.astechmarketing.com/images/RVUN.png', 'https://www.astechmarketing.com/images/shree cement.jpeg', 'https://www.astechmarketing.com/images/SJVN.png', 'https://www.astechmarketing.com/images/sravanthi.jpeg', 'https://www.astechmarketing.com/images/UPRVINL.png', 'https://www.astechmarketing.com/images/vendanta.png', 'https://www.astechmarketing.com/images/wonder.png']

const delhiOptions = ['Pragati Power Corporation Ltd.', 'Indraprastha Power Generation Co. Ltd.', 'NTPC, Badarpur Thermal Power Station']
const upOptions = ['Narora Atomic Power Station, Bulandshahar', 'Parichha Thermal Power Station, Jhansi', 'Obra Thermal Power Station, Sonebhadra', 'Anpara Thermal Power Station, Sonebhadra', 'Harduaganj Thermal Power Station, Aligarh', 'Panki Thermal Power Station, Kanpur', 'NTPC, Dadri', 'NTPC, Unchachar', 'NTPC, Vindhyachal, Vindh Nagar', 'NTPC, Singrauli, Shakti Nagar', 'NTPC, Rihand', 'NTPC, Auraiya', 'IOCL, Mathura Refinery']
const haryanaOptions = ['Panipat Thermal Power Station', 'Deenbandu Chotu Ram Thermal Power Station, Yamuna Nagar', 'Rajiv Gandhi Thermal Power Station, Hissar', 'NTPC, Faridabad Gas Power Project, Ballabgarh', 'NTPC, Indira Gandhi Super Thermal Power Plant, Jhajjar', 'IOCL, Panipat Refinery', 'IOCL, Panipat (Neptha Cracker)']
const punjanOptions = ['Guru Gobind Singh Super Thermal, Ropar', 'Guru Nanak Dev Thermal, Bhatinda', 'Guru Har Gobind Thermal, Lehra Mohabbat']
const rajisthanOptions = ['Rajasthan Atomic Power Project', 'Suratgarh Super Thermal Power Station, Suratgarh', 'Ramgarh Super Thermal Power Station', 'Kota Super Thermal Power Station', 'Giral Lignite Power Limited', 'Dholpur Combined Cycle Power Plant', 'Chhabar Thermal Power Station']
const JkOptions = ['Uri 2', 'Khyber Cement']
const ukOptions = ['Roquette India', 'Saravanthi Energy', 'Ultra Tech, Roorkee']
const himachalOptions = ['Ultra Tech, Baga', 'NTPC - Koldam', 'Himchal Sorang Power']
const currentlyWorkingOptions = ['NHPC - Teesta 5,6', 'NHPC - Loktak', 'NHPC - 1,2,3']

export default function Home() {
    const navigate = useNavigate();
    const [modalOpen, setModelOpen] = useState("false")
    const [stateName, setStateName] = useState('')
    const [stateOptions, setStateOptions] = useState([])

    const closeModal = () => {
        setModelOpen(false)
    }
    const openPopup = (name, options) => {
        setStateName(name)
        setStateOptions(options)
        setModelOpen(true)
    }

    const renderOptions = (optionsArr) => {
        return optionsArr.slice(0, 3).map((res) => {
            return <li>{res}</li>
        })
    }
    return (
        <>
            <Header />
            {stateName &&
                <Modal show={modalOpen} onHide={closeModal} >
                    <Modal.Header closeButton>
                        <Modal.Title>{stateName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ paddingLeft: '40px' }}>
                        <ul style={{ listStyleType: 'disc' }}>
                            {stateOptions.map((res) => {
                                return <li style={{ listStyleType: 'disc' }}>{res}</li>
                            })}
                        </ul>
                    </Modal.Body>
                </Modal>
            }
            <main className="home-page">
                <section className="banner-section" id="Home">
                    <div className="container">
                        <h1>Original Equipment, Prompt Service brought at your doorstep - Responsibly</h1>
                        <h3 className='contenttxt'> We introduce ourselves as a leading company in the marketing of Industrial & Electrical equipments being authorized dealers of OEM's. ASTECH Marketing Pvt. Ltd. is a Delhi based company formed in 2003. In the year 1983 the promoters of the company
                            started its business with the design development, manufacture and supply of different equipments being used in power plants. </h3>
                    </div>
                </section>
                <section className="four-points">
                    <div className="container">
                        <ul>
                            <li>
                                <div className="blue-box"></div>
                                <div className="info contenttxt">The span of almost two decades has made us realise, that more than products, it is understanding the client's requirement and giving maximum service to the customer at minimum cost is what makes us stand apart form others. Over the years we have supplied OEM products to many prestigious projects key industrial sectors namely - Power Stations (Thermal, Hydro & Atomic), Oil & Gas, Steel, Fertilizer, Cement, Paper, Beverages, STP & WTP, etc. During these years the company has expanded its network all over the country and is marketing Original Equipment Manufacturer products of Indian and Foreign origin. Our major product line comprises of:
                                    <br /><br />
                                    <div>
                                        <ul className='listitem-ul'>
                                            <li className='liitm'> Electrical Actuators & Gearboxes</li>
                                            <li className='liitm'> Centrifuge Separators, Plate Heat Exchangers & Decanters</li>
                                            <li className='liitm'> Resins, Polyelectrolyte & Water Treatment Chemicals</li>
                                            <li className='liitm'> AC & DC Motors</li>
                                            <li className='liitm'> Electrical Switchgear</li>
                                        </ul>
                                    </div>

                                </div>
                            </li>
                            <li>
                                <div className="blue-box"></div>
                                <div className="info contenttxt">We are catering to customers of all sizes, We value from a single piece of requirement to large turnkey project & gives maximum service to all. We follow stringent Quality Standards to ensure that you always get maximum in terms of product quality, performance & service.
                                </div>
                            </li>
                            {/* <li>
                                <div className="blue-box"></div>
                                <div className="info">Comfort produce husband boy her had hearing. Law others theirs passed but
                                    wishes. You day real less till dear read. Considered use dispatched melancholy sympathize
                                    discretion led. </div>
                            </li>
                            <li>
                                <div className="blue-box"></div>
                                <div className="info">Comfort produce husband boy her had hearing. Law others theirs passed but
                                    wishes. You day real less till dear read. Considered use dispatched melancholy sympathize
                                    discretion led. </div>
                            </li> */}
                        </ul>
                    </div>
                </section>
                <section className="about-us" id="Aboutus">
                    {/* style={{color:'#212529'}} */}
                    <div className="container">
                        <h2 >About Us</h2>
                        <div className="left-portion">
                            <h2>MISSION:</h2>
                            <br />
                            <p className=''>"To provide best in class services to our clients and customers to enhance productivity and efficiency of Power Industry"</p>

                            <h2>POLICY:</h2>
                            <br />
                            <p contenttxt>"We accept challenging jobs"</p>

                        </div>

                        <div className="right-portion">
                            <p style={{ lineHeight: 2 }} className='contenttxt'>
                                Promoters of the company initially started business of design development and manufacture of different tailor made items such as distance pieces, flanges, couplings, shafts and shaft sleeves by fabrication / casting / machining. These items are widely used in Thermal, Hydro and Atomic Power Stations, Refineries, Steel, Fertilizer, Cement Industries etc.
                                <br />
                                ASTECH Marketing Pvt. Ltd. is a Delhi based company formed in 2003. We are committed to supply original equipments directly from manufacturers at a very competitive price.
                            </p>
                        </div>
                    </div>
                </section>

                <section className="building" id="OurCustomer">
                    <div className="building-content">
                        <h2 style={{ color: '#212529' }}>Our Customers</h2>
                        <div className='row-1'>
                            <div className='galleryy-img'>
                                <div className='list-img'>
                                    <img src={logo[3]} />
                                </div>
                                <div className='list-img'>
                                    <img src={logo[1]} />
                                </div>
                                <div className='list-img'>
                                    <img src={logo[2]} />
                                </div>
                                <div className='list-img'>
                                    <img src={logo[3]} />
                                </div>
                                <div className='list-img'>
                                    <img src={logo[4]} />
                                </div>
                                <div className='list-img'>
                                    <img src={logo[5]} />
                                </div>
                                <div className='list-img'>
                                    <img src={logo[6]} />
                                </div>
                                <div className='list-img'>
                                    <img src={logo[7]} />
                                </div>
                                <div className='list-img'>
                                    <img src={logo[8]} />
                                </div>
                                <div className='list-img'>
                                    <img src={logo[9]} />
                                </div>
                                <div className='list-img'>
                                    <img src={logo[10]} />
                                </div>
                                <div className='list-img'>
                                    <img src={logo[11]} />
                                </div>
                                <div className='list-img'>
                                    <img src={logo[12]} />
                                </div>
                                <div className='list-img'>
                                    <img src={logo[13]} />
                                </div>
                                <div className='list-img'>
                                    <img src={logo[14]} />
                                </div>
                                <div className='list-img'>
                                    <img src={logo[15]} />
                                </div>
                                <div className='list-img'>
                                    <img src={logo[16]} />
                                </div>
                                <div className='list-img'>
                                    <img src={logo[17]} />
                                </div>

                                <div className='list-img'>
                                    <img src={logo[18]} />
                                </div>
                                <div className='list-img'>
                                    <img src={logo[19]} />
                                </div>
                                <div className='list-img'>
                                    <img src={logo[20]} />
                                </div>
                                <div className='list-img'>
                                    <img src={logo[21]} />
                                </div>
                                <div className='list-img'>
                                    <img src={logo[22]} />
                                </div>
                                <div className='list-img'>
                                    <img src={logo[23]} />
                                </div>
                                <div className='list-img'>
                                    <img src={logo[24]} />
                                </div>
                                <div className='list-img'>
                                    <img src={logo[25]} />
                                </div>
                                <div className='list-img'>
                                    <img src={logo[26]} />
                                </div>
                                <div className='list-img'>
                                    <img src={logo[27]} />
                                </div>
                                <div className='list-img'>
                                    <img src={logo[28]} />
                                </div>
                                <div className='list-img'>
                                    <img src={logo[29]} />
                                </div>
                                <div className='list-img'>
                                    <img src={logo[30]} />
                                </div>
                                <div className='list-img'>
                                    <img src={logo[31]} />
                                </div>
                                <div className='list-img'>
                                    <img src={logo[32]} />
                                </div>

                                {/* <div className='list-img'>
                                    <img src={logo3} />
                                </div>
                                <div className='list-img'>
                                    <img src={logo3} />
                                </div>
                                <div className='list-img'>
                                    <img src={logo3} />
                                </div>
                                <div className='list-img'>
                                    <img src={logo3} />
                                </div>
                                <div className='list-img'>
                                    <img src={logo3} />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
                <section className="program" id="OurPrincipal">
                    <div className="container">
                        <h3 style={{ paddingTop: '10px' }}>Our Principals</h3>
                        <ul>
                            <li>
                                {/* <div className="blue-box">01</div> */}
                                <div className=""></div>
                                <div onClick={() => navigate('/companies/1')}>

                                    <img src='https://www.astechmarketing.com/images/auma_logo.jpg' />
                                    <div className="info"><a style={{ textDecoration: 'underline', cursor: 'pointer' }} >AUMA India Pvt. Ltd.</a></div>
                                </div>
                            </li>
                            <li >
                                <div className=""></div>
                                <div style={{ marginTop: "37px" }} onClick={() => navigate('/companies/2')}>


                                    <img src={alfalaval} />
                                    <div className="info"><a style={{ textDecoration: 'underline', cursor: 'pointer' }} >Alfa Laval India Pvt. Ltd.</a> </div>
                                </div>
                            </li>

                            <li>
                                <div className=""></div>
                                <div onClick={() => navigate('/companies/3')}>

                                    <img src='https://www.astechmarketing.com/images/kirloskar_logo.jpg' />
                                    <div className="info"><a style={{ textDecoration: 'underline', cursor: 'pointer' }} >Kirloskar Electric Co. Ltd. </a></div>
                                </div>
                            </li>

                            <li>
                                <div className=""></div>
                                <div onClick={() => navigate('/companies/4')}>

                                    <img src={iosImg} />
                                    <div className="info"><a style={{ textDecoration: 'underline', cursor: 'pointer' }} >ION Exchange (India) Ltd.</a> </div>
                                </div>
                            </li>

                        </ul>
                    </div>
                </section>
                <section className="carousel" id="ProductRange">
                    <div className="container">
                        <h2>Product Range</h2>
                        <div className="carousel-cover">
                            <AutoplaySlider
                                play={true}
                                cancelOnInteraction={false} // should stop playing on user interaction
                                interval={6000}
                            >
                                <div className="carousel-slide">
                                    <div className="info">
                                        <a style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => navigate('/companies/1')}>AUMA India Pvt. Ltd.</a>
                                        <ul>
                                            <li style={{ color: 'black', textDecoration: 'nono', cursor: 'pointer' }} onClick={() => navigate('/companies-details/1')}>Actuators</li>
                                            <li style={{ textDecoration: 'nono', cursor: 'pointer' }} onClick={() => navigate('/companies-details/2')}>Gear Box</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <img src='https://www.astechmarketing.com/images/auma_logo.jpg' />
                                    </div>
                                </div>
                                <div className="carousel-slide">
                                    <div className="info">
                                        <a style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => navigate('/companies/2')}>Alfa Laval India Pvt. Ltd.</a>
                                        <ul>
                                            <li style={{ textDecoration: 'nono', cursor: 'pointer' }} onClick={() => navigate('/companies-details/3')}>Centrifuge Separators</li>
                                            <li style={{ textDecoration: 'nono', cursor: 'pointer' }} onClick={() => navigate('/companies-details/4')}> Decanters</li>
                                            <li style={{ textDecoration: 'nono', cursor: 'pointer' }} onClick={() => navigate('/companies-details/5')}>Plate type Heat Exchangers (PHE)</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <img src='https://www.astechmarketing.com/images/alfa_logo.jpg' />
                                    </div>
                                </div>
                                <div className="carousel-slide">
                                    <div className="info">
                                        <a style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => navigate('/companies/3')}>Kirloskar Electric Co. Ltd.</a>
                                        <ul>
                                            <li style={{ textDecoration: 'nono', cursor: 'pointer' }} onClick={() => navigate('/companies-details/6')}>AC Motors</li>
                                            <li style={{ textDecoration: 'nono', cursor: 'pointer' }} onClick={() => navigate('/companies-details/7')}>DC Motors</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <img src='https://www.astechmarketing.com/images/kirloskar_logo.jpg' />
                                    </div>
                                </div>
                                <div className="carousel-slide">
                                    <div className="info">
                                        <a style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => navigate('/companies/4')}>ION Exchange (India) Ltd.</a>
                                        <ul>
                                            <li style={{ textDecoration: 'nono', cursor: 'pointer' }} onClick={() => navigate('/companies-details/8')}>Resins</li>
                                            <li style={{ textDecoration: 'nono', cursor: 'pointer' }} onClick={() => navigate('/companies-details/9')}>Water Treatment Chemicals</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <img src={iosImg} />
                                    </div>
                                </div>

                            </AutoplaySlider>
                        </div>
                    </div>
                </section>
                <section className="signature">
                    <div className="container">
                        <div className="signature-content">
                            <div>
                                <h2>We are the best company to guarantee you successful deals</h2>
                                <h4>We work directly for our clients and put client’s interests first.</h4>
                            </div>
                            <div>
                                <img src={sign} alt="Sign" />
                                <p>Rajesh Gupta, COO</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="contact-us" id="ContactUs">
                    <div className="container">
                        <div className="left-portion">
                            <h2>Contact Us</h2>
                            <p>Email us with any questions or inquiries or feel free to call us. We would be happy to answer your questions & set up a meeting with you.</p>
                        </div>
                        <div className="right-portion">
                            <h3>Postal address</h3>
                            <h4>Plot No. 2, APS Building,</h4>
                            <h4>Near Shakti Nagar,</h4>
                            <h4>Delhi - 110 007, INDIA</h4>
                            <h4>Ph: +91-011-27453308</h4>
                            <br />
                            <h3>Email contact</h3>
                            <h4>info@astechmarketing.com</h4>
                        </div>
                    </div>
                </section>
            </main>
            <footer>
                <div className="container">
                    <p>Sample text. Click to select the Text Element.</p>
                </div>
            </footer>
        </>
    )
}
