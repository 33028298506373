import React, { useEffect, useState } from 'react'
import Header from '../header/header'
import { useNavigate, useParams } from "react-router-dom";
import './details.css';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
const AutoplaySlider = withAutoplay(AwesomeSlider);



export default function Details() {
  const navigate = useNavigate()
  const { id } = useParams()
  const [names, setName] = useState('')
  const [cnames, setCName] = useState('')


  useEffect(() => {
    let name = ''
    let cname = ''
    if (id == 1) {
      name = 'Actuators'
      cname = 'AUMA India Pvt Ltd'
    } else if (id == 2) {
      name = 'Gear Box'
      cname = 'AUMA India Pvt Ltd'
    } else if (id == 3) {
      name = 'Centrifuge Separators'
      cname = 'Alfa Laval India Pvt Ltd.'
    } else if (id == 4) {
      name = 'Decanter'
      cname = 'Alfa Laval India Pvt Ltd.'
    } else if (id == 5) {
      name = 'Plate type Heat Exchangers (PHE)'
      cname = 'Alfa Laval India Ltd.'
    } else if (id == 6) {
      name = 'AC Motors'
      cname = 'Kirloskar Electric Company Ltd'
    } else if (id == 7) {
      name = 'DC Motors'
      cname = 'Kirloskar Electric Company Ltd'
    } else if (id == 8) {
      name = 'Resins'
      cname = 'ION Exchange (India) Ltd.'
    } else if (id == 9) {
      name = 'Water Treatment Chemicals'
      cname = 'ION Exchange (India) Ltd.'
    } else{
      name = 'Actuators'
      cname = 'AUMA India Pvt Ltd'
    }

    setCName(cname)
    setName(name)
  }, [id])
  return (
    <>
      <Header />
      <section className="company-page container">
        <br />

        {id == 1 &&

          <div>
            <p><a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/')}>Home </a> &lt;-&gt; {names} &lt;-&gt; <a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/companies-details/2')}>Gear Boxes </a></p>
            <div className='space-between flex-wrap'>
              <h4>{cnames}</h4>
            </div>
            <br />

            <AutoplaySlider

              play={true}
              cancelOnInteraction={false} // should stop playing on user interaction
              interval={3000}
            >
              <div> <img height={'330px'} src='https://www.astechmarketing.com/images/auma/Multi turn actuator.jpg' /></div>
              <div> <img src='	https://www.astechmarketing.com/images/auma/Auma_Compact.jpg' /></div>

              <div> <img src='	https://www.astechmarketing.com/images/auma/multi_integral_compact.jpg' /></div>
              <div> <img src='	https://www.astechmarketing.com/images/auma/multi_integral_epac.jpg' /></div>
              <div> <img src='	https://www.astechmarketing.com/images/auma/multi_integral_explosion.jpg' /></div>

            </AutoplaySlider>
            <br /><br />
            <div style={{ display: 'grid' }}>

              <div class='detail-container'>
                <div class='img'>
                  <img src='https://www.astechmarketing.com/images/auma/SA_Type.jpg' />
                </div>
                <div class='text'>
                  <h4><b>Open Close Duty</b></h4>
                  The characteristic feature of this actuator is open loop. The normal valve positions in OPEN-CLOSE Duty are end positions OPEN and CLOSED. After receiving command, the actuator operates the valve to one of the end positions or if necessary to a preset intermediate position. The valves are operated relatively seldom, the time intervals can span between a few minutes to several months.
                </div>
              </div>

              <div class='detail-container'>
                <div class='img'>
                  <img src='https://www.astechmarketing.com/images/auma/SAR_Type.jpg' />
                </div>
                <div class='text'>
                  <h4><b>Regulating / Modulating Duty</b></h4>

                  The characteristic feature of this actuator is closed loop in which input command signal to actuator is directly influenced by the flow through the valve. Regulating / Modulating duty actuator SAR operates between two set bands between open-close positions depending upon actual valve position and feedback signal. The motors of these actuators are rated for intermittent duty S4-25%. The mechanical components and motor are designed to withstand a large number of operations required for modulating applications.

                  <ul>
                    <li className='outer-list'>Description of Regulating Duty</li>
                    <ul className='inner-ul'>
                      Auma actuators of type SAR are suitable for Regulating duty / Modulating duty. SAR
                      actuator operates between two set bands in closed loop and the motor is started, stopped
                      &amp; reversed at short intervals to correct the deviation from the set value. SAR actuators
                      differ from SA actuators in several respects :
                      <li className='inner-list'>4 pole motors are used to reduce overshoot</li>
                      <li className='inner-list'>Worm with high quality finish on flanks for better efficiency</li>
                      <li className='inner-list'>Disc springs on worm shaft are pre compressed to reduce dead band when reversing.</li>
                    </ul>
                    <br />

                    While selecting Auma actuators for regulating duty applications, following points should be noted :
                    <br /><br />
                    <ul className='inner-ul'>
                      <li className='inner-list'>Max. No. of starts 1200 cycles / hour with motor power upto 0.55 kW &amp; 600 cycles / hour with motor power above 0.55 kW. </li>
                      <li className='inner-list'>Output speed of actuator max. 45 rpm. </li>
                      <li className='inner-list'>Torque for regulating duty should not exceed 50% of max. torque of actuator. </li>
                      <li className='inner-list'>Max. torque of actuator can be used for seating &amp; unseating of the valve. </li>
                      <li className='inner-list'>For reversing service, duration of impulse should be minimum of 50 ms. </li>
                      <li className='inner-list'>Electronic position transducer or precision potentiometer should be used for remote position indication.  </li>
                      <li className='inner-list'>The motor should be short time duty class S4 - 25%. </li>
                    </ul>
                  </ul>
                </div>
              </div>

              <div class='detail-container'>
                <div class='img'>
                  <img src='https://www.astechmarketing.com/images/auma/Norm.jpg' />
                </div>
                <div class='text'>
                  <h4><b>Auma Norm Actuators</b></h4>
                  This actuator offers a complete actuator type SA with electric motor, torque switch for open - close direction, limit switches for both end positions & blinker switch for running indication. The required reversing contactor should be installed separately & wired to the motor. For signalization of the switching and to trip the contactors over micro switches, control wires are required from actuator terminal compartment cover to reversing contactor installed separately
                </div>
              </div>
              <div class='detail-container'>
                <div class='img'>
                  <img src='https://www.astechmarketing.com/images/auma/Integral%20Starter.jpg' />
                </div>
                <div class='text'>
                  <h4><b>AUMA Integral Starter Actuators</b></h4>
                  <p>The purpose of offering Integral motor controls for the actuator is to enable the customer to save high installation costs for external controls. Actuators with integral controls include control & switching elements and are supplied. All electrical components such as limit, torque, thermo switches, all monitoring elements & position transmitter are integrated into modern controls. This results in following simplification - ready for use.
                  </p>
                  <ul style={{ marginLeft: '30px' }} className='inner-list'>
                    <li className='inner-listone'>No extensive wiring in the external control cabinet.</li>
                    <li className='inner-listone'>Several actuators can be connected to common actuator.</li>
                    <li className='inner-listone'>Actuator signals are processed in the controls, only feed back to process control system is necessary.</li>
                    <li className='inner-listone'>Integral starter housing can be easily exchanged due to multi pin plug connector between actuator &amp; integral starter unit.</li>
                    <li className='inner-listone'>Actuator can be operated from Local or Remote position by means of selector switch on integral starter unit.</li>
                  </ul>
                </div>
              </div>

              <div class='detail-container'>
                <div class='img'>
                  <img src='https://www.astechmarketing.com/images/auma/Auma_Compact.jpg' />
                </div>
                <div class='text'>
                  <h4><b>Auma Compact</b></h4>
                  <p className='contenttxt'>This is a simpler version of Integral Starter Unit with selector switch & push buttons. The selector switch has 3 positions :</p>
                  <ul style={{ marginLeft: '30px' }} className='inner-list'>
                    <li className='inner-listone'><b>LOCAL : </b>   The actuator can be operated locally i.e. at the actuator by push buttons OPEN-OFF-CLOSE. The running direction is indicated by LED's. </li>
                    <li className='inner-listone'><b>REMOTE : </b>   The actuator can be operated from remote control station. The running direction is
                      indicated by LED's on the actuator. </li>
                    <li className='inner-listone'><b>OFF : </b>   The actuator cannot be operated with local controls or from remote. </li>
                  </ul>
                  <p className='contenttxt'>The selector switch can be locked in any position with pad lock. The compact unit is completely wired with reversing contactors and no additional control box is required.</p>
                  <p className='contenttxt'>Auma compact is available as Norm or Regulating duty actuator. Depending upon Norm or Regulating duty, following features / optionals are available :</p>

                  <ul style={{ marginLeft: '30px' }} className='inner-list'>
                    <li className='inner-listone'>Reversing contactors</li>
                    <li className='inner-listone'>Selector switch </li>
                    <li className='inner-listone'>Push buttons &amp; indicating lamps </li>
                    <li className='inner-listone'>Thermal overload relay </li>
                    <li className='inner-listone'>Power supply unit PS01 for 24 V DC output </li>
                    <li className='inner-listone'>Electronic positioner CU01 </li>
                    <li className='inner-listone'>Electronic position transducer RWG / IWG </li>
                    <li className='inner-listone'>Phase discriminator </li>
                    <li className='inner-listone'>Signal isolator </li>
                    <li className='inner-listone'>DC relay for remote operation </li>
                    <li className='inner-listone'>Remote annunciation relay for supply indication</li>
                  </ul>

                </div>
              </div>


              <div class='detail-container'>
                <div class='img'>
                  <img src='https://www.astechmarketing.com/images/auma/Auma_e-pac.jpg' />
                </div>
                <div class='text'>
                  <h4><b>Auma e-pac</b></h4>
                  The electronic version of Auma India Integral Starter e-pac incorporates sophisticated electronic controls with field programming feature. E-pac is modular in construction and consists of Power Supply Module, Relay Module & Programmable Control Module.
                  <br /><br />
                  <div>
                    <ul>
                      <li className='outer-list'>Power Supply Module consists of</li>
                      <ul className='inner-ul'>
                        <li className='inner-list'>24 V DC, 150 mA source</li>
                        <li className='inner-list'>Reversing contactors electrically and mechanically interlocked</li>
                        <li className='inner-list'>Selector switch LOCAL-OFF-REMOTE, lockable & potential free contacts for local off & remote</li>
                        <li className='inner-list'>Push buttons OPEN-STOP-CLOSE for local controls</li>
                        <li className='inner-list'>Single phase supply for space heater 10W</li>
                      </ul>
                      <li className='outer-list'>Relay Module consists of</li>
                      <ul className='inner-ul'>
                        <li className='inner-list'>Monitor relay for collective fault signals (Thermo switch trip, torque switch trip in mid travel) with potential free contacts</li>
                        <li className='inner-list'>Local status annunciation (Green) & fault annunciation (Red) by LED's</li>

                      </ul>
                      <li className='outer-list'>Programmable Control Logic Module consists of</li>
                      <ul className='inner-ul'>
                        <li className='inner-list'>Inching & Non-inching in local mode by dip switches</li>
                        <li className='inner-list'>Inching & Non-inching in remote mode by dip switches</li>
                        <li className='inner-list'>Limit switch seating or Torque seating by dip switches</li>

                      </ul>
                    </ul>
                  </div>
                  <p>Wide varieties of versions of e-pac & optional features make the actuator controls adaptable to any field situation. Versions & optionals available are :</p>
                  <ul>
                    <li className='hrfPointer'>Emergency shut down (ESD) </li>
                    <li className='hrfPointer'>2 wire make-break </li>
                    <li className='hrfPointer'>Timer board to run the actuator in ON-OFF steps </li>
                    <li className='hrfPointer'>Open-Close inhibit </li>
                    <li className='hrfPointer'>Negative switching </li>
                    <li className='hrfPointer'>Remote control &amp; local stop </li>
                    <li className='hrfPointer'>Electronic positioner for regulating duty, with or without isolator </li>
                    <li className='hrfPointer'>Single phase protection </li>
                    <li className='hrfPointer'>Automatic phase correction </li>
                    <li className='hrfPointer'>Remote annunciation relays </li>
                    <li className='hrfPointer'>Thermal overload relay </li>
                    <li className='hrfPointer'>Positioner with PID features </li>
                    <li className='hrfPointer'>Wall mountable e-pac </li>
                  </ul>
                  <p>Wide varieties of versions of e-pac & optional features make the actuator controls adaptable to any field situation. Versions & optionals available are :</p>

                  <p>
                    <p><b>Download Product Catalogue</b></p>
                    <p>
                      <ul>
                        <li><a href="https://www.astechmarketing.com/images/auma/xploded_Actuator1.pdf" target="_blank" style={{ color: '#373737' }}>Operation Manual & Spare parts list</a></li>
                        <li><a href="https://www.astechmarketing.com/images/auma/Auma - Electric Actuatos.pdf" target="_blank" style={{ color: '#373737' }} >Complete Manual</a></li>
                      </ul>
                    </p>
                  </p>
                  <ul>
                    <li align="justify"><a href="http://www.astechmarketing.com/auma" target="_blank" style={{ fontSize: '14px' }}>Online Training</a></li>
                    <li align="justify"><a href="../../contactus.asp?item=auma_actutator" style={{ fontSize: '14px' }} >Request a <b>quote</b>    for this product</a></li>
                    <li align="justify"><a href="http://www.auma.com/cms/AUMA/india/1,111004,29180.html/" target="_blank" style={{ fontSize: '14px' }} >Visit AUMA's Website</a></li>
                  </ul>
                </div>
              </div>

            </div>
          </div>}

        {id == 2 &&
          <div>
            <p><a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/')}>Home </a> &lt;-&gt; <a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/companies-details/1')}>Actuators </a> &lt;-&gt; {names} </p>
            <div className='space-between flex-wrap'>
              <h4>{cnames}</h4>
              {/* <img src='https://www.astechmarketing.com/images/alfa_logo.jpg' /> */}
            </div>
            <br />
            <p>

              AUMA India Gear Boxes combine with AUMA India Actuators to complete the valve control service. There are basically three types of Gear Boxes:
              <ul className='inner-ul'>
                <li className='inner-listone'>Worm Gear Boxes - GF & GS Series</li>
                <li className='inner-listone'>Bevel Gear Boxes - GK Series</li>
                <li className='inner-listone'>Spur Gear Boxes - GST Series</li>
              </ul>

              All Gear Boxes are manufactured to comply with AUMA Germany specifications. The housings are designed for high rigidity to absorb high torsional and thrust loads. The Gear Box enclosure conforms to IP 67 class. IP 68 enclosures can also be supplied on request. All Gear Boxes are lubricated with special grease for life.                        </p>

            <div style={{ display: 'grid' }}>

              <div class='detail-container'>
                <div class='img'>
                  <img src='	https://www.astechmarketing.com/images/auma/worm_gear%20box.gif' />
                </div>
                <div class='text'>
                  <h4><b>WORM GEAR BOXES</b></h4>
                  <h4><b>GS 40 - GS 500</b></h4>
                  AUMA India GS type Gear Boxes are designed for rotation upto 135O with end stop or 360O operation of Ball Valves, Butterfly Valves, Plug Valves, Dampers etc. The extremely rugged construction features positive adjustable end stop on input shaft for precise valve positioning.
                  <br /><br />
                  The Gear Boxes are designed for output torque from 300 Nm (GS 40) to 250,000 Nm (GS 500) for motor operation. The main features of GS Gear Boxes are:
                  <br /><br />
                  <ul className='inner-ul'><li className='inner-listone'>
                    Full 360O worm wheel design, making the Gear Box suitable either for multiturn or quarter turn operation.
                  </li></ul>
                  In case of quarter turn operation, additional three segments are available for repositioning, thus extending the life of worm wheel by three times.
                  <br /><br />  <ul className='inner-ul'>
                    <li className='inner-listone'>  A separate coupling with involute splines is provided for coupling to the valve. The coupling is removable for machining the bore to suit valve shaft. The orientation of the Gear Box while mounting on valve is made simple due to involute splines on the coupling.
                    </li> <li className='inner-listone'> Unique design of end stoppers on input shaft with screws and nut mechanism allows precise and positive setting upto 135 operation.
                    </li> <li className='inner-listone'> Each Gear Box is designed for use with Actuator to facilitate economical automation of valves.
                    </li> <li className='inner-listone'> To reduce the input torque, for a given output of Worm Gear Box, a primary reduction Gear Box can be easily attached at input of the Gear Boxes. For this purpose, GS 100 & GS 125 Gear Boxes are fitted with planetary reduction Gear Box (VZ4) and higher series are fitted with Spur Reduction Gear Boxes (GZ) of various ratios. This feature enables selection of smaller actuators for economical sizing.
                    </li> </ul>
                </div>
              </div>

              <div class='detail-container'>
                <div class='img'>
                  <img src='	https://www.astechmarketing.com/images/auma/gf_gear_box.jpg' />
                </div>
                <div class='text'>
                  <h4><b>WORM GEAR BOXES</b></h4>
                  <h4><b>TYPE GF 40 - GF 250</b></h4>
                  GF Series of Gear Boxes are specially used for actuation of Dampers and Fly Valves which require quarter turn applications.
                  <br />
                  <br />  The gear operator is a worm Gear Box of GS Series with a built-in floor stand and a crank lever. The crank lever is provided with a number of mounting ball and socket joints at varying moment arms to suit given damper/valve application. All other features of GF series are same as GS Gear Boxes. GF Gear Boxes in sizes higher than GF 250 can be supplied on request.

                  <br /><br />  GF Series Gear Boxes can also be coupled to primary reduction Gear Boxes and also to AUMA India Actuators to obtain ON-OFF Control or regulating duty.
                </div>
              </div>


              <div class='detail-container'>
                <div class='img'>
                  <img src='	https://www.astechmarketing.com/images/auma/gk_gear%20box.gif' />
                </div>
                <div class='text'>
                  <h4><b>WORM GEAR BOXES</b></h4>
                  <h4><b>TYPE GK 10-GK 35</b></h4>
                  Bevel Gear Boxes are designed to operate all types of rising or non-rising stem valves and gate valves where thrust may or may not be taken by the Gear Box. The bore of the output bevel gear allows valve stem to pass through it to reach its full travel.

                  <br /><br />   GK bevel Gear Boxes are available for wide range of torque and thrust load capacity ranging from 120 Nm torque x 60 KN thrust (GK 10) to 8000 Nm torque x 820 KN thrust (GK 35).

                  <br /><br /> Various types of output drives (Type A to Type E) are available, depending upon application.

                  <br /><br />   GK Gear Boxes are designed considering power operation and are easily adapted to Actuators, thus enabling small actuators to be economically sized for operation of large valves.
                </div>
              </div>

              <div class='detail-container'>
                <div class='img'>
                  <img src='https://www.astechmarketing.com/images/auma/gst_gear%20box.gif' />
                </div>
                <div class='text'>
                  <h4><b>SPUR GEAR BOXES</b></h4>

                  GST range of spur Gear Boxes are designed for operation of rotating stem valves. They can be combined with Actuators or can be easily operated by hand wheels. GST Gear Boxes can be easily adapted for Globe, Angle and Non return valves. They produce an output torque of 120 Nm (GST 12) to 16000 Nm (GST 1600).


                  <p>
                    <p><b>Download Product Catalogue</b>   </p>
                    <p><ul>
                      <li><a href="https://www.astechmarketing.com/images/auma/Gearbox Manual.pdf" target="_blank" style={{ color: '#373737' }}>Gear Box Catalogue complete</a></li>
                      <li><a href="https://www.astechmarketing.com/images/auma/GS_40-500_Exploded.pdf" target="_blank" style={{ color: '#373737' }} >Part Description Leaflet GS Series</a></li>
                      <li><a href="https://www.astechmarketing.com/images/auma/xploded_GK.pdf" target="_blank" style={{ color: '#373737' }} >Part Description Leaflet GK Series</a></li>
                    </ul></p>
                  </p>
                  <ul>
                    <li align="justify"><a href="http://www.astechmarketing.com/auma" target="_blank" style={{ fontSize: '14px' }}>Online Training</a></li>
                    <li align="justify"><a href="../../contactus.asp?item=auma_actutator" style={{ fontSize: '14px' }} >Request a <b>quote</b>    for this product</a></li>
                    <li align="justify"><a href="http://www.auma.com/cms/AUMA/india/1,111004,29180.html/" target="_blank" style={{ fontSize: '14px' }} >Visit AUMA's Website</a></li>
                  </ul>

                </div>

              </div>

            </div>


          </div>}

        {id == 3 &&
          <div>
            <p><a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/')}>Home </a> &lt;-&gt; {names} &lt;-&gt; <a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/companies-details/4')}>Decanters </a> &lt;-&gt; <a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/companies-details/5')}>Plate type Heat Exchangers (PHE) </a>  </p>
            <div className='space-between flex-wrap'>
              <h4>{cnames}</h4>
              {/* <img src='https://www.astechmarketing.com/images/kirloskar_logo.jpg' /> */}
            </div>
            <br />
            <div style={{ display: 'grid' }}>

              <div class='detail-container'>
                <div class='img'>
                  <img src='https://www.astechmarketing.com/images/alfa/Separator.jpg' />
                </div>
                <div class='text'>


                  Separators with high rotation speeds undertake the continuous separation of a wide range of different solids from either one or two liquids. High-speed separators are primarily used for separating fluids.

                  <br /><br />Due to the centrifugal force in separators, the fluid/particles with the greatest density collect in the outer periphery of the separator bowl. Both fluid phases are then discharged through separate outlets.

                  <br /><br />Alfa Laval has comprehensive know-how in the design of the disc stack which is the heart of the separator and the key to good separation performance.

                  <br /><br />
                  <p>
                    <p><b>Download Spare Parts Catalogue</b>   </p>
                    <p><ul>
                      <li><a href="https://www.astechmarketing.com/images/alfa/MAB 103 SPC.pdf" target="_blank" style={{ color: '#373737' }}>MAB 103</a></li>
                      <li><a href="https://www.astechmarketing.com/images/alfa/MAB 206 SPC.pdf" target="_blank" style={{ color: '#373737' }} >MAB 206</a></li>
                      <li><a href="https://www.astechmarketing.com/images/alfa/MMB 304 SPC.pdf" target="_blank" style={{ color: '#373737' }} >MMB 304</a></li>
                      <li><a href="https://www.astechmarketing.com/images/alfa/MMB 305 SPC.pdf" target="_blank" style={{ color: '#373737' }} >MMB 305</a></li>
                    </ul></p>
                  </p>
                  <ul>
                    <li><a href='https://www.alfalaval.in'>Visit ALFA LAVAL's Website</a></li>
                    <li style={{ cursor: "pointer", textDecoration: "underline", color: "#0d6efd" }} onClick={() => navigate('/')}><a href='#ContactUs'>Request a quote for ALFA Products</a></li>
                  </ul>   </div>
              </div>

            </div>


          </div>}

        {id == 4 &&
          <div>
            <p><a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/')}>Home </a> &lt;-&gt; <a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/companies-details/3')}>Centrifuge Separators </a> &lt;-&gt; {names} &lt;-&gt; <a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/companies-details/5')}>Plate type Heat Exchangers (PHE) </a>  </p>
            <div className='space-between flex-wrap'>
              <h4>{cnames}</h4>
              {/* <img src='https://www.astechmarketing.com/images/kirloskar_logo.jpg' /> */}
            </div>
            <br />
            <div style={{ display: 'grid' }}>

              <div class='detail-container'>
                <div class='img'>
                  <img src='https://www.astechmarketing.com/images/alfa/decanter.jpg' />
                </div>
                <div class='text'>
                  Decanter centrifuges are normally based on <b>horizontal separation technology</b> and work at slower speeds. With different design, lengths and cone geometries, Alfa Laval’s range of decanters can be applied in any processing environment that requires <b> two or three-phase separation of solids and liquids</b>. An important application is <b> dewatering of sludge </b>in wastewater treatment plants.

                  <p>
                    <p><b>Download Product Catalogue</b>   </p>
                    <p><ul>
                      <li><a href="https://www.astechmarketing.com/images/alfa/leaflet_aldec_series.pdf" target="_blank" style={{ color: '#373737' }}>ALDEC- High Performance Decanter</a></li>
                    </ul></p>
                  </p>
                  <ul>
                    <li><a href='https://www.alfalaval.in'>Visit ALFA LAVAL's Website</a></li>
                    <li style={{ cursor: "pointer", textDecoration: "underline", color: "#0d6efd" }} onClick={() => navigate('/')}><a href='#ContactUs'>Request a quote for ALFA Products</a></li>
                  </ul>
                </div>
              </div>

            </div>

          </div>}


        {id == 5 &&
          <div>
            <p><a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/')}>Home </a> &lt;-&gt; <a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/companies-details/3')}>Centrifuge Separators </a> &lt;-&gt; <a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/companies-details/4')}>Decanters</a> &lt;-&gt; {names}    </p>
            <div className='space-between flex-wrap'>
              <h4>{cnames}</h4>
              {/* <img src='https://www.astechmarketing.com/images/kirloskar_logo.jpg' /> */}
            </div>
            <br />
            <div style={{ display: 'grid' }}>

              <div class='detail-container'>
                <div class='img' style={{ padding: '10px' }}>
                  <img src='https://www.astechmarketing.com/images/alfa/phe_170x162.jpg' />
                </div>
                <div class='text'>
                  Alfa Laval is the world’s leading supplier of plate heat exchangers, all characterized by their <b>compact design and high thermal efficiency</b>. The product portfolio includes various types of plate heat exchangers such as
                  <br /><br />
                  <ul className='inner-ul'>
                    <li className='inner-listone'>Gasketed</li>
                    <li className='inner-listone'>Semi-welded and fully welded</li>
                    <li className='inner-listone'>Brazed</li>
                    <li className='inner-listone'>Fusion-bonded </li>
                  </ul>
                  Plate heat exchangers are made up of a series of assembled, corrugated plates. Between the plates there are two channels with a cold and a warm medium. These pass on each side of the plates and in opposite direction to each other.
                  <br /><br />

                  Gasketed plate heat exchangers, which are used for a large number of applications, are <b> sealed with rubber gaskets</b>. Brazed plate heat exchangers have been developed to cope with higher pressures and temperatures. Welded plate heat exchangers are used to handle even <b> higher pressures</b> and temperatures.

                  {/* <p>
                <p><b>Download Product Catalogue</b>   </p>
                <p><ul>
                  <li><a href="https://www.astechmarketing.com/images/alfa/leaflet_aldec_series.pdf" target="_blank" style={{ color: '#373737' }}>Product cataloguer</a></li>


                </ul></p>
              </p> */}
                  <br /> <br />
                  <ul>
                    <li><a href='https://www.alfalaval.in'>Visit ALFA LAVAL's Website</a></li>
                    <li style={{ cursor: "pointer", textDecoration: "underline", color: "#0d6efd" }} onClick={() => navigate('/')}><a href='#ContactUs'>Request a quote for ALFA Products</a></li>
                  </ul>
                </div>
              </div>

            </div>

          </div>}


        {id == 6 &&
          <div>
            <p><a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/')}>Home </a> &lt;-&gt; {names} &lt;-&gt; <a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/companies-details/7')}> DC Motors </a> </p>
            <div className='space-between flex-wrap'>
              <h4>{cnames}</h4>
              {/* <img src='https://www.astechmarketing.com/images/kirloskar_logo.jpg' /> */}
            </div>
            <br />
            <div style={{ display: 'grid' }}>

              <div class='detail-container'>
                <div class='img'>
                  <img width={'200px'} src='https://www.astechmarketing.com/images/kirloskar/acmotor.jpg' />
                </div>
                <div class='text'>
                  Kirloskar Electric was the first company in India to manufacture AC Motors way back in 1948. Since then it has manufactured millions of motors for domestic and International Market. Its initial technology support from BRUSH Electric UK & technical collaborations with NEI Peebles Electrical Machines, Scotland and AEG of Germany enables KEC to develop high capacity motors for various applications. A strategic alliance with FUJI Electric of Japan enabled the company to absorb and implement state of art technology for motors.
                  <br /> <br />
                  The product range in capacity and variety caters to multitude applications. KEC has electric motors for every conceivable application and industry. The wide industrial range is from 0.12 to 20000 kW in frames 63 to 1250. Motors are offered in open type, closed type and heat exchanger type enclosures. Voltages range from 340 to 13800 V. Class F insulation being standard for all motors, class H insulation is also offered for low voltage motors.
                  <br /> <br />
                  The rugged and highly reliable higher range motors are specifically designed for exacting duties. The modular design totally enclosed air to air heat exchanger is the first choice of industry for the more arduous applications. Compact rib cooled motors with unique internal cooling are used for duty atmospheres.
                  <br /> <br />
                  Kirloskar electric offers AC Generators from 1kVA to 20MVA, voltage from 220 to 11000 V, and speed from 3000 rpm down to 375rpm, frequencies 50 or 60Hz., or even special frequencies like 200 or 400Hz. These machines can have enclosures of open and closed type with air or water cool arrangements. Mechanical construction can have single or double bearing arrangements and variety of excitation system both brushless and brush type, with class ‘F’ or class ‘H’ insulation, meeting the performance they requirements of national and international standards. In the aspect of dimension, size, weight, aesthetics and performance they are comparable to the best of the world.
                </div>
              </div>
              <br /> <br />
              <div class='detail-container'>
                <div class='img'>
                  <img src='https://www.astechmarketing.com/images/kirloskar/acmotors1.jpg' />
                </div>
                <div class='text'>
                  The Special motors list includes Roller Table motor, Mud Gun motor, Bell Annealing Furnace motor for steel mill applications. When India faced sanctions on supply for equipment for Nuclear Power Plants, KEC developed special Canned Pump Motors. Motors like explosion proof, increase safety and non-sparking ones used extensively in hazardous areas like coal mines, oil mines and petrochemical industries are available in a wide range. Specially designed adjustable speed drive motors in large numbers are working in various steel plants and other industries.
                  <br /> <br />
                  KEC is the only company in India and one among the 18 in the whole world to have the unique certified facility for efficiency testing of induction motors up to 50hp. The laboratory has been certified by NVLAP (National Voluntary Lab Accreditation Program), USA .
                  <br /> <br />
                  <p style={{ color: 'blue' }}>Applications:</p>
                  <b>Industries</b>	:	Agriculture, Construction, Cement, Paper, Petrochemicals, Steel, Sugar, Textile.<br />
                  <b>Power Plants	</b>:	Nuclear, Thermal.<br />
                  <b>Utilities</b>	:	Cinema, Gas Station, Hospitals, Hotels, Mill, Telecom.<br />
                  <b>Military</b>	:	Auxiliary power to fire armaments from land & Sea.


                  <p>
                    {/* <p><b>Download Product Catalogue</b>   </p>
                <p><ul>
                  <li><a href="https://www.astechmarketing.com/images/alfa/leaflet_aldec_series.pdf" target="_blank" style={{ color: '#373737' }}>Product Catalogue</a></li>


                </ul></p> */}
                  </p>
                  <ul>
                    <li><a href='https://kirloskarelectric.com/index.php'>Visit Kirloskar's Website</a></li>
                    <li style={{ cursor: "pointer", textDecoration: "underline", color: "#0d6efd" }} onClick={() => navigate('/')}><a href='#ContactUs'>Request a quote for KIRLOSKAR Products</a></li>
                  </ul>    </div>
              </div>

            </div>


          </div>}



        {id == 7 &&
          <div>
            <p><a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/')}>Home </a> &lt;-&gt; <a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/companies-details/6')}>AC Motors </a> &lt;-&gt; {names} </p>
            <div className='space-between flex-wrap'>
              <h4>{cnames}</h4>
              {/* <img src='https://www.astechmarketing.com/images/kirloskar_logo.jpg' /> */}
            </div>
            <br />
            <div style={{ display: 'grid' }}>

              <div class='detail-container'>
                <div class='img'>
                  <img src='https://www.astechmarketing.com/images/kirloskar/dcmachines.jpg' />
                </div>
                <div class='text'>
                  <p>Kirloskar Electric forms its fabled stable of DC Motors have put into international use more than 0.1 million motors since 1969. The wide product range in capacity and variety satisfies the needs of a multitude of applications. The Industrial Laminated Yoke range starting from frame 80 to 1250 with outputs of 0.75 to 3250 kW caters to the industry requirement of torques up to 200 kNm. The rugged and reliable higher range of motors are specifically designed for exacting duties in a hot and cold metal rolling mill drive, the sticky rubber mixer drive and continues duty cement kiln drive. These products come from an in house developed technology based on the best of world class international design and manufacturing concepts.</p>
                  <p>Kirloskar Electric has a history of excelling in special application and customization. It is this spirit that saw us develop complete electric for 5 & 10 cubic meter Electric Shovels for coal mines. Today, there are more than 150 Electric Rope working in Indian coal mines, completely powered by Kirloskar Electrics. India 's premier aircraft manufacturing facility in Bangalore houses one of the world's few high power and outdoor duty vertical DC motors, for helicopter blade load testing made by Kirloskar.</p>
                  <p>Continuing our efforts in supplying clean & efficient motive power to the environment friendly electric material handling equipment, it is only natural that KEC moves on to electric passenger vehicles. The prestigious CERN Super Particle Accelerator project in Geneva uses Kirloskar Electric Super Conducting DC Corrector Magnets in very large numbers. This is a high precision product of extreme reliability and has to work in a cryogenic environment.</p>
                </div>
              </div>

            </div>
            <div style={{ display: 'grid' }}>

              <div class='detail-container'>
                <div class='img'>
                  <img src='https://www.astechmarketing.com/images/kirloskar/dcgnes.jpg' />
                </div>
                <div class='text'>
                  <p>The constant pursuit of newer applications has pushed KEC to enter space applications. Our space duty Brush less DC Servo Motors will provide the Rocket Nozzle control force in Indian space launch vehicle for the future.</p>
                  <p>The fully equipped and highly quality manufacturing facility backed by a strong force of dedicated and skilled design, marketing and service team with a focus on quality brings in great levels of reliability to the product that is trusted in India and abroad.</p>
                  <p>
                    <br /><br />
                    <b>Applications:</b>
                  </p>
                  <p><b>Industries</b>	:	Cement, Sugar, Rubber, Plastic, Steel, Textile, Paper, Printing.</p>
                  <ul>

                    <li style={{ cursor: "pointer", textDecoration: "underline", color: "#0d6efd" }} onClick={() => navigate('/')}><a href='#ContactUs'>Request a quote for this Products</a></li>
                    <li><a href='https://kirloskarelectric.com/index.php'>Visit Kirloskar's Website</a></li>

                  </ul>
                </div>
              </div>

            </div>


          </div>}

        {id == 8 &&
          <div>
            <p><a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/')}>Home </a> &lt;-&gt; {names} &lt;-&gt; <a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/companies-details/9')}> Water Treatment Chemicals </a> </p>
            <div className='space-between flex-wrap'>
              <h4>{cnames}</h4>
              {/* <img src='https://www.astechmarketing.com/images/kirloskar_logo.jpg' /> */}
            </div>
            <br />
            <div style={{ display: 'grid' }}>

              <div class='detail-container'>

                <div class='text'>
                  <img width={'100%'} src='https://www.astechmarketing.com/images/ion/resin.jpg' />
                  <br />
                  <p>Specialists in water and environment management, Ion Exchange (India) Limited is among few companies worldwide with an entire range of technologies, products and processes, enabling water & environment solutions for infrastructure, industrial, municipal & household applications.</p>

                  <p><b>FOUR DECADES OF EXPERTISE IN ION EXCHANGE RESINS</b></p>
                  <p>With 40 years of expertise in the manufacture of ion exchange resins to international standards, we are positioned as the market leaders in India.
                  </p>
                  <p>
                    Specialists in water and environment management, Ion Exchange (India) Limited is among few companies worldwide with an entire range of technologies, products and processes, enabling water & environment solutions for infrastructure, industrial, municipal & household applications.
                  </p><ul>
                    <li style={{ listStyle: "disc" }}>Complete range of gel, isoporous & macroporous cation and anion exchange resins for water and waste water treatment as well as specialty applications in pharmaceutical, nuclear and chemical industry.</li>
                    <li style={{ listStyle: "disc" }}>INDION resins dominate the Indian market and are exported widely, to USA, UK, Europe, South East Asia, Japan and other countries.</li>
                    <li style={{ listStyle: "disc" }}><b>ISO 9001:2000 and ISO 14001</b> certified fully automated manufacturing facility at Ankleshwar, Gujarat.</li>
                    <li style={{ listStyle: "disc" }}><b>FDA(India)</b> approved pharmaceutical grade resin manufacturing facility.</li>
                    <li style={{ listStyle: "disc" }}>Backed by in-house <b>R&D.</b></li>
                  </ul>

                  <b>Applications:</b>
                  <ul>
                    <li style={{ listStyle: "disc" }}> Industrial Water Treatment</li>
                    <li style={{ listStyle: "disc" }}>Portable Water Treatment</li>
                    <li style={{ listStyle: "disc" }}>Nuclear Grade Resins</li>
                    <li style={{ listStyle: "disc" }}>Catalyst Grade Resins</li>
                    <li style={{ listStyle: "disc" }}>Chemical Process Applications</li>
                  </ul>

                  <p>
                    <p><b>Download Resin Product Sheet</b>   </p>
                    <p><ul>
                      <li><a href="https://www.astechmarketing.com/images/alfa/225_H_PDS.pdf" target="_blank" style={{ color: '#373737' }}>INDION 225 H PDS</a></li>
                      <li><a href="https://www.astechmarketing.com/images/alfa/225_Na_PDS.pdf" target="_blank" style={{ color: '#373737' }}>INDION 225 Na PDS</a></li>
                      <li><a href="https://www.astechmarketing.com/images/alfa/850_PDS.pdf" target="_blank" style={{ color: '#373737' }}>INDION 850 PDS</a></li>
                      <li><a href="https://www.astechmarketing.com/images/alfa/2250_H_PDS.pdf" target="_blank" style={{ color: '#373737' }}>INDION 2250 H PDS</a></li>
                      <li><a href="https://www.astechmarketing.com/images/alfa/FF_IP_PDS.pdf" target="_blank" style={{ color: '#373737' }}>INDION FFIP PDS</a></li>
                      <li><a href="https://www.astechmarketing.com/images/alfa/790_PDS.pdf" target="_blank" style={{ color: '#373737' }}>INDION 790 PDS</a></li>
                      <li><a href="https://www.astechmarketing.com/images/alfa/GS_300_PDS.pdf" target="_blank" style={{ color: '#373737' }}>INDION GS 300 PDS</a></li>

                    </ul></p>
                  </p>

                  <ul>
                    <li><a href='https://ionexchangeglobal.com'>Visit Ion Exchange Website</a></li>
                    <li style={{ cursor: "pointer", textDecoration: "underline", color: "#0d6efd" }} onClick={() => navigate('/')}><a href='#ContactUs'>Request a quote for ION Products</a></li>
                  </ul>
                </div>
              </div>

            </div>


          </div>}

        {id == 9 &&
          <div>
            <p><a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/')}>Home </a> &lt;-&gt; <a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/companies-details/8')}>Resins </a> &lt;-&gt; {names} </p>
            <div className='space-between flex-wrap'>
              <h4>{cnames}</h4>
              {/* <img src='https://www.astechmarketing.com/images/kirloskar_logo.jpg' /> */}
            </div>
            <br />
            <div style={{ display: 'grid' }}>

              <div class='detail-container'>

                <div class='text'>
                  Enabling us to provide total and integrated solutions is our wide product range which caters to every market - household, commercial, institutional, industrial and public water supplies - urban and rural.
                  <br /><br /> <p>
                    <b>Applied Ion Exchange</b>    Purification of glucose, dextrose, sorbitol, formaldehyde solution, recovery of vitamin C and tartaric acid
                  </p>
                  <p>
                    <b>Arsenic Removal Units</b>    For potable water
                  </p>
                  <p>
                    <b>Boiler Water Chemicals	</b>    Corrosion Inhibitors, Sludge Conditioners
                  </p>
                  <p>
                    <b>Cooling Water Chemicals</b>    Biocides, Antiscalants, Descalants, Corrosion Inhibitors
                  </p>
                  <p>
                    <b>Condensate Polishers</b>    Deep Bed

                  </p>
                  <p>
                    <b>Chemical Dosers</b>    Metering Pumps , Differential Pressure Operated Systems
                  </p>
                  <p>
                    <b>Clarifiers</b>    Lamella, High/Ultra High Rate Solids Contact
                  </p>

                  <p>
                    <b>Desalination Systems</b>    For Brackish, Sea Water and Waste Water
                  </p>
                  <p>
                    <b>Deionisers</b>    Downflow, Upflow, Counterflow and Splitflow, Two Bed, Three Bed, Four Bed and Mixed Bed
                  </p>
                  <p>
                    <b>Dealkalisers</b>    Weak Acid Cation, Split Stream
                  </p>
                  <p>
                    <b>Degassers</b>    Atmospheric, Vacuum
                  </p>
                  <p>
                    <b>Deaerators</b>    Spray, Spray-cum-tray , Vacuum
                  </p>

                  <p>
                    <b>Defoamers</b>    For cooling water and boilers
                  </p>
                  <p>
                    <b>Dip Slides</b>    For bacterial count in cooling water, food, beverages and pharmaceuticals
                  </p>
                  <p>
                    <b>Drinking Water Purifiers</b>    Portable, on-tap, on-line, table top, under-the-counter and storage type

                  </p>
                  <p>
                    <b>Filters</b>    Pressure Sand, Multigrade, Multimedia, Upflow, Activated Carbon and Continuous Sand
                  </p>
                  <p>
                    <b>Fluoride Removal Unit</b>    For potable water - point-of-use, handpump & power pump attachments, commun
                  </p>
                  <p>
                    <b>Iron Removal Units</b>    For industrial and potable water (point-of-use, handpump attachments, community systems)

                  </p>
                  <p>
                    <b>Ion Exchange Resins</b>    A wide range of cationic and anionic exchangers - gel, isoporous, macroporous, catalyst and pharmaceutical grade, adsorbent, chelating resins
                  </p>
                  <p>
                    <b>Membranes</b>    A wide range of membranes and membrane processes for fluid purification and recovery separation
                  </p>
                  <p>
                    <b>Membrane Separation Systems</b>    Micro, Ultrafiltration, Nanofiltration and Reverse Osmosis
                  </p>
                  <p>
                    <b>Arsenic Removal Units</b>    For potable water - handpump attachments, community systems
                  </p>
                  <p>
                    <b> Nitrate Removal Units</b>    For potable water - handpump attachments, community systems

                  </p>
                  <p>
                    <b> Oxidising Agents</b>    Electrochlorinators for on-site generation of chlorine from brine and ozonators for treatment of drinking water, swimming pools, cooling water and waste water
                  </p>
                  <p>
                    <b> Oil Removal Systems</b>    Ultrafiltration, Coalescers, Drum Skimmers, Polymers, Activated Carbon
                  </p>
                  <p>
                    <b> Packaged Sewage Treatment Plants</b>    For hotels, hospitals, institutions and residential complexes
                  </p>
                  <p>
                    <b> Paper Process Chemicals</b>    Dry strength additive, retention & drainage aids, waste water treatment , defoamers, de-inking chemicals, disperants, slimicides, etc.

                  </p>
                  <p>
                    <b> Polyelectrolytes</b>    Flocculants for water and waste water treatment, mineral processing, paper, sugar and other industries.

                  </p>
                  <p>
                    <b> Reverse Osmosis Chemicals	</b>    Antiscalants & Cleaners
                  </p>
                  <p>
                    <b> Softeners</b>    Cold Lime and Base Exchange
                  </p>
                  <p>
                    <b> Selectively Adapted Bacterial Cultures</b>    In effluent treatment for removal of cyanide, phenols, oil and grease and increased efficiencies of BOD removal

                  </p>
                  <p>
                    <b> Solvent Recovery System	</b>    Based on Activated Carbon
                  </p>
                  <p>
                    <b> Sugar Process Chemicals</b>    Biocides for mill sanitation chemicals, bagasse pol reducers, flocculants for juice clarification, polymeric scale inhibitors,viscosity reducers, speciality ion exchangers and adsorbents,boiler water treatment chemicals etc.
                  </p>
                  <p>
                    <b> Surfactants</b>    For cooling water and evaporators
                  </p>
                  <p>
                    <b> Test Kits</b>    For analysis of water and waste water
                  </p>
                  <p>
                    <b> Water Quality
                      Monitoring Instruments</b>    pH/ORP, conductivity, dissolved oxygen, turbidity, ammonia.
                  </p>
                  <p>
                    <b> Water Stations</b>   Drinking water vending machines
                  </p>
                  <p>
                    <b> Waste Water Treatment & Water Recycle Systems</b>   For industry, institutions and residential complexes, using physical, chemical, biological and membrane separation processes.
                  </p>

                  <div className='gallery-img'>
                    <div className='list-img'>
                      <img src='https://www.astechmarketing.com/images/ion/6.jpg' />
                    </div>
                    <div className='list-img'>
                      <img src='https://www.astechmarketing.com/images/ion/lamclar.jpg' />
                    </div>
                    <div className='list-img'>
                      <img src='https://www.astechmarketing.com/images/ion/dminplnt.jpg' />
                    </div>
                    <div className='list-img'>
                      <img src='https://www.astechmarketing.com/images/ion/bwt.jpg' />
                    </div>
                    <div className='list-img'>
                      <img src='https://www.astechmarketing.com/images/ion/dpcp.jpg' />
                    </div>
                    <div className='list-img'>
                      <img src='https://www.astechmarketing.com/images/ion/cwt.jpg' />
                    </div>
                    <div className='list-img'>
                      <img src='https://www.astechmarketing.com/images/ion/dealk.jpg' />
                    </div>
                    <div className='list-img'>
                      <img src='https://www.astechmarketing.com/images/ion/mbu.jpg' />
                    </div>
                    <div className='list-img'>
                      <img src='https://www.astechmarketing.com/images/ion/09.jpg' />
                    </div>
                    <div className='list-img'>
                      <img src='https://www.astechmarketing.com/images/ion/condpol.jpg' />
                    </div>
                    <div className='list-img'>
                      <img src='https://www.astechmarketing.com/images/ion/psf.jpg' />
                    </div>
                    <div className='list-img'>
                      <img src='https://www.astechmarketing.com/images/ion/vd.jpg' />
                    </div>
                    <div className='list-img'>
                      <img src='https://www.astechmarketing.com/images/ion/rop.jpg' />
                    </div>
                    <div className='list-img'>
                      <img src='https://www.astechmarketing.com/images/ion/membrane.jpg' />
                    </div>
                    <div className='list-img'>
                      <img src='https://www.astechmarketing.com/images/ion/9.jpg' />
                    </div>
                    <div className='list-img'>
                      <img src='https://www.astechmarketing.com/images/ion/domestic_water.jpg' />
                    </div>

                    <div className='list-img'>
                      <img src='https://www.astechmarketing.com/images/ion/p.jpg' />
                    </div>
                    <div className='list-img'>
                      <img src='https://www.astechmarketing.com/images/ion/10.jpg' />
                    </div>

                  </div>

                </div>
              </div>

            </div>
            <p>
              <b>Air Pollution Control</b>    Collision Scrubber, Scrubber, Biofilter, Regenerative Catalytic Oxidiser (ROC), Regenerative Thermal Oxidiser (RTO), Multifluid Thermal Oxidiser, Mist Eliminator, Mist Scrubber
            </p>
            <p>
              <b> Solid Waste</b>   Horizontal Disk Dryer, Dryer-Granulator, Multifluid Thermal, Oxidise, Grate, Rotary Furnace, Fluidised Bed Reactor
            </p>
            <p>
              <b> Renewable Energy</b>  Anaerobic Systems, Horizontal Disk Dryer, Dryer-Granulator
            </p>

            <p>

              <p><ul>
                {/* <li><a href="https://www.astechmarketing.com/images/alfa/leaflet_aldec_series.pdf" target="_blank" style={{ color: '#373737' }}>ALDEC- High Performance Decanter</a></li> */}


              </ul></p>
            </p>
            <ul>
              <li><a href='https://ionexchangeglobal.com'>Visit Ion Exchange Website</a></li>
              <li style={{ cursor: "pointer", textDecoration: "underline", color: "#0d6efd" }} onClick={() => navigate('/')}><a href='#ContactUs'>Request a quote for ION Products</a></li>
            </ul>

          </div>}



      </section>
    </>
  )
}
