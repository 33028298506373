import React, { useEffect, useState } from 'react'
import Header from '../header/header'
import '../home/home.css';
import iosImg from '../../assets/images/ion.jpeg'
import alfalaval from '../../assets/images/logo-alfalaval.svg'

import { useNavigate, useParams } from "react-router-dom";

export default function Company() {
    const navigate = useNavigate()
    const { id } = useParams()
    const [names, setName] = useState('')


    useEffect(() => {
        let name = ''
        if (id == 1) {
            name = 'AUMA India Pvt Ltd'
        } else if (id == 2) {
            name = 'Alfa Laval India Pvt Ltd.'
        } else if (id == 3) {
            name = 'Kirloskar Electric Company Ltd'
        } else if (id == 4) {
            name = 'ION Exchange (India) Ltd.'
        }
        setName(name)
    }, [id])
    return (
        <>
            <Header />
            <section className="company-page container">
            <br />
               
                {id == 1 &&
                    <div>
                    <p><a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/')}>Home </a>  &lt;-&gt; {names} &lt;-&gt; <a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/companies/2')}>Alfa Laval </a> &lt;-&gt; <a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/companies/3')}>Kirloskar </a> &lt;-&gt; <a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/companies/4')}>ION Exchange </a></p>
                        <div className='space-between flex-wrap'>
                            <h4>{names}</h4>
                            <img src='https://www.astechmarketing.com/images/auma_logo.jpg' />
                        </div>
                        <br />
                        <p className='contenttxt'>
                            Water or oil, steam or gas – nothing remains in one place for long. Motion and flow are the driving forces of any system or plant, however large or small. Electric actuators operating gate, globe, ball and butterfly valves determine the rhythm of flow. They are integrated into control systems and respond to control signals. Actuators are linked to control centres or monitoring consoles, or they rely on their own intelligent electronics. Where there is flow there is AUMA.

                            Valve and machine actuators – an idea turns into a product.

                            <br /><br />


                           <div>
                            <ul>Products manufactured:
                                <li className='hrfPointer' style={{cursor:"pointer",textDecoration:"underline",color:"#0d6efd"}} onClick={()=>navigate('/companies-details/1')}>Electrical Actuators</li>
                                <li className='hrfPointer' style={{cursor:"pointer",textDecoration:"underline",color:"#0d6efd"}} onClick={()=>navigate('/companies-details/2')}>Gear Box</li>
                            </ul>
                           </div>                        
                          
                        <ul>
                            
                            <li><a href='http://www1.auma.com/cms/AUMA/india/service/1,111004,102382.html'>Online Training</a></li>
                            <li><a href='http://www1.auma.com/cms/AUMA/india/'>Visit AUMA's Website</a></li>
                            <li style={{cursor:"pointer",textDecoration:"underline",color:"#0d6efd"}} onClick={()=>navigate('/')}><a href='#ContactUs'>Request a quote for AUMA Products</a></li>
                        </ul>
                        </p>
                    </div>}
                {id == 2 &&
                    <div>
                    <p><a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/')}>Home </a> &lt;-&gt; <a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/companies/1')}>AUMA India Pvt Ltd </a> &lt;-&gt; {names} &lt;-&gt; <a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/companies/3')}>Kirloskar </a> &lt;-&gt; <a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/companies/4')}>ION Exchange </a></p>
                        <div className='space-between flex-wrap'>
                            <h4>{names}</h4>
                            <img src={alfalaval} />
                        </div>
                        <br />
                        <p className='contenttxt'>

                            Alfa Laval is a leading global provider of first-rate products in the areas of heat transfer, separation and fluid handling. 
                            With these as its base, Alfa Laval aims to help enhance the productivity and competitiveness of its customers in various industries throughout the world. 
                            We define their challenges and deliver sustainable products and solutions that meet their requirements – mainly in energy, the environment, food and the marine industry.
			    Alfa Laval is a world leader in three key technologies <br /> <br />
				1. HEAT TRANSFER: Alfa Laval’s heat exchangers transfer heating or cooling from, for example, one liquid to another. 
                          	   They recycle heat, optimize customers’ energy consumption, cut costs and reduce their environmental impact. <br /> <br />
				2. SEPARATION: The technology is used to separate liquids from other liquids and solid particles from liquids or gases. 
				   The offering includes separators, decanter centrifuges, filters, strainers and membranes. <br /> <br />
				3. FLUID HANDLING: We supply pumps, valves, tank cleaning equipment and installation material for the food and pharmaceutical industries and pumping systems for the marine sector and offshore market.




                            <br /><br />
                            <div>
                            <ul>Products handled by us:
                                <li className='hrfPointer' style={{cursor:"pointer",textDecoration:"underline",color:"#0d6efd"}} onClick={()=>navigate('/companies-details/3')}> Centrifuge Separators</li>
                                <li className='hrfPointer' style={{cursor:"pointer",textDecoration:"underline",color:"#0d6efd"}} onClick={()=>navigate('/companies-details/4')}> Decanters</li>
                                <li className='hrfPointer' style={{cursor:"pointer",textDecoration:"underline",color:"#0d6efd"}} onClick={()=>navigate('/companies-details/5')}>Plate type Heat Exchangers (PHE)</li>
                            </ul>
                           </div>
                           
                           <ul>                           
                            <li><a href='https://www.alfalaval.in'>Visit ALFA LAVAL's Website</a></li>
                            <li style={{cursor:"pointer",textDecoration:"underline",color:"#0d6efd"}} onClick={()=>navigate('/')}><a href='#ContactUs'>Request a quote for ALFA Products</a></li>
                        </ul> 
                        </p>
                    </div>}
                {id == 3 &&
                    <div>
                    <p><a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/')}>Home </a> &lt;-&gt; <a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/companies/1')}>AUMA India Pvt Ltd </a> &lt;-&gt; <a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/companies/2')}>Alfa Laval </a> &lt;-&gt; {names} &lt;-&gt; <a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/companies/4')}>ION Exchange </a></p>
                        <div className='space-between flex-wrap'>
                            <h4>{names}</h4>
                            <img src='https://www.astechmarketing.com/images/kirloskar_logo.jpg' />
                        </div>
                        <br />
                        <p className='contenttxt'>
                            Kirloskar Electric Company Ltd. is one of the leading Indian electrical engineering companies. Established in 1946, Kirloskar Electric was the catalyst for industrialisation in India.

                            Kirloskar Electric produces more than 70 products under eight product groups. They cater to core economic sectors such as power generation, transmission & distribution, transportation, and renewable energy, to name a few.

The Kirloskar Electric organization comprises nine manufacturing locations and 34 sales offices spread across the country. They have a large network of spare parts dealers and service centres. This enables them to provide premium products, exemplary systems & services at competitive rates to our clients.

Kirloskar Electric products are known for their high quality, durability, and reliability. This is due to their emphasis on design, engineering, and manufacturing. They adhere to international standards by acquiring & adapting latest technologies along with in-house R&D.

                            <br /> <br />
                            <div>
                            <ul>Products manufactured:
                                <li className='hrfPointer' style={{cursor:"pointer",textDecoration:"underline",color:"#0d6efd"}} onClick={()=>navigate('/companies-details/6')}> AC Motors</li>
                                <li className='hrfPointer' style={{cursor:"pointer",textDecoration:"underline",color:"#0d6efd"}} onClick={()=>navigate('/companies-details/7')}> DC Motors</li>
                            </ul>
                           </div>
                                                      
                           <ul>                           
                            <li><a href='https://kirloskarelectric.com/index.php'>Visit Kirloskar's Website</a></li>
                            <li style={{cursor:"pointer",textDecoration:"underline",color:"#0d6efd"}} onClick={()=>navigate('/')}><a href='#ContactUs'>Request a quote for KIRLOSKAR Products</a></li>
                        </ul>
                        </p>
                    </div>}
                {id == 4 &&
                    <div>
                    <p><a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/')}>Home </a> &lt;-&gt; <a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/companies/1')}>AUMA India Pvt Ltd </a> &lt;-&gt; <a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/companies/2')}>Alfa Laval </a> &lt;-&gt; <a style={{ color: 'cornflowerblue', fontWeight: 'bold', cursor: 'pointer', }} onClick={() => navigate('/companies/3')}>Kirloskar </a> &lt;-&gt;{names}</p>
                        <div className='space-between flex-wrap'>
                            <h4>{names}</h4>
                            <img src={iosImg} />
                        </div>
                        <br />
                        <p className='contenttxt'>
                           A pioneer of water treatment in India with a legacy spanning over five decades, Ion Exchange is today a premier company in water and environment management with a global presence. Formed in 1964, as a subsidiary of the Permutit Company of UK, Ion Exchange became a wholly Indian company in 1985 when Permutit divested their holding. Over the years, they have accumulated a wealth of experience and are now recognized as a leading solutions provider, one of very few companies worldwide with complete range of solutions for Water, Wastewater Treatment, Solid Waste Management and Waste to Energy.

Their comprehensive technologies, products and services, enable them multidisciplinary teams of experts to cater to Industries, Institutions, Municipalities, Communities, and Homes.

With sales, production and service footprints across the globe, they serve markets with a sustained focus on customer satisfaction, technological innovation and dedicated service.

                            <br /> <br />
                            <div>
                            <ul>Products manufactured:
                                <li className='hrfPointer' style={{cursor:"pointer",textDecoration:"underline",color:"#0d6efd"}} onClick={()=>navigate('/companies-details/8')}> Resins</li>
                                <li className='hrfPointer'  style={{cursor:"pointer",textDecoration:"underline",color:"#0d6efd"}} onClick={()=>navigate('/companies-details/9')}>  Water Treatment Chemicals</li>
                            </ul>
                           </div>
                           <ul>                           
                            <li><a href='https://ionexchangeglobal.com'>Visit Ion Exchanges's Website</a></li>
                            <li style={{cursor:"pointer",textDecoration:"underline",color:"#0d6efd"}} onClick={()=>navigate('/')}><a href='#ContactUs'>Request a quote for ION Products</a></li>
                        </ul> 
                        </p>
                    </div>}
            </section>
        </>
    )
}
