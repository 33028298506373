import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import menuIcon from '../../assets/images/menu.png';
import logo from '../../assets/images/logo.jpg';
import './header.css';

export default function Header() {
    const navigate = useNavigate();
    const [isOpenMenu,setOpenMenu]= useState(false)
    
    const toggalMenu=()=>{
        let isOpen = !isOpenMenu
        setOpenMenu(isOpen)
    }
    console.log('isOpenMenu',isOpenMenu)
    return (
        <div className="page-header">
            <a onClick={()=>navigate('/')} className="logo-cover">
                <img src={logo} alt="Logo" />
            </a>
            <div className="right-portion">
                <ul className="links-cover">
                    <li onClick={()=>navigate('/')}><a href="#Home">Home</a></li>
                    <li onClick={()=>navigate('/')}><a href="#Aboutus">About Us</a></li>
                    <li onClick={()=>navigate('/')}><a href="#OurPrincipal">Our Principals</a></li>
                    <li onClick={()=>navigate('/')}><a href="#OurCustomer">Our Customers</a></li>
                    <li onClick={()=>navigate('/')}><a href="#ProductRange">Product Range</a></li>
                    <li onClick={()=>navigate('/')}><a href="#ContactUs">Contact Us</a></li>
                </ul>
                <div className="camp-side-cover">
                    <div className="for-mobile">
                        <div  className="hamburger-cover" onClick={toggalMenu}>
                            <img  src={menuIcon} alt="Menu" />
                        </div>
                        <div className={isOpenMenu ? "sidebar-cover active" : "sidebar-cover"}>
                            <div className="sidebar">
                                <ul>
                                    <li onClick={()=>navigate('/')}><a onClick={toggalMenu} href="#Home"><h4>Home</h4></a></li>
                                    <li onClick={()=>navigate('/')}><a onClick={toggalMenu} href="#Aboutus"><h4>About Us</h4></a></li>
                                    <li onClick={()=>navigate('/')}><a  onClick={toggalMenu}href="#OurPrincipal"><h4>Our Principals</h4></a></li>
                                    <li onClick={()=>navigate('/')}><a onClick={toggalMenu} href="#OurCustomer"><h4>Our Customers</h4></a></li>
                                    <li onClick={()=>navigate('/')}><a onClick={toggalMenu} href="#ProductRange"><h4>Product Range</h4></a></li>
                                    <li onClick={()=>navigate('/')}><a onClick={toggalMenu} href="#ContactUs"><h4>Contact Us</h4></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}