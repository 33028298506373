import { BrowserRouter, Route, Routes } from "react-router-dom";
import Company from "../screens/company";
import Details from "../screens/details";
import Home from "../screens/home";

function Router(){
    return(
        <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home  />} />
          <Route path="/companies/:id" element={<Company  />} />
          <Route path="/companies-details/:id" element={<Details  />} />
        </Routes>
      </BrowserRouter>
    )
}

export default Router